<template>
    <div class="text-left">
      <!-- <BaseHeader :title="'Sites'"></BaseHeader> -->
  
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <section v-else>
        <div class=" mt-2">
          <div class="text-right d-flex p-3 card d-flex align-items-center flex-row w-100 justify-content-between" style>
            <v-text-field
            v-model="$store.state.search"
              dense
              outlined
              label="Filter News"
              name="url"
              type="text"
              hide-details
            />
            <v-spacer></v-spacer>
            <router-link :to="{ name: 'NewEmail' }"
              class="my-auto btn-add ml-2"
              
              >Add News</router-link
            >
          </div>
          <!-- datatable -->
          <v-data-table
            :mobile-breakpoint="0"
            :headers="newsTableAdmin"
            :items="notifications"
            :search="$store.state.search"
          >
            <template v-slot:[`item.is_read`]="{ item }">
              <v-chip v-if="item.is_read == 1" class="ma-2" color="primary" small
                >Read</v-chip
              >
              <v-chip v-else class="ma-2" color="error"  small
                >Unread</v-chip
              >
            </template>
            <!-- <template v-slot:[`item.setups`]="{ item }">
              <v-btn color="primary"  outlined  elevation="0" @click="openSetups = true ; Setups(item); activeSite == item; openSetups == true" >
                Setups
              </v-btn>
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{ name: 'SingleNew', params: { id: item.id } }" class="btn btn-outline-primary rounded"   >View</router-link>
            </template>

            <template v-slot:[`item.id`]="{ item }">
                <router-link :to="{ name: 'SingleNew', params: { id: item.id } }"   >{{item.id}}</router-link>
            </template>
          </v-data-table>
          <!-- /datatable -->
  
          <div class=" mx-0   order-table-custom ">

  
      </div>
        </div>
  
       
        <!-- setups modal -->
      </section>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import {setupsMenu
  } from "../../components/sidebar.model";
  export default {
    name: "Sites",
    computed: {
      ...mapState("sites", [
        "sitesForm",
        "loading",
        "sites",
        "headers",
        "adminSystems",
      ]),
      ...mapState("clients", ["clients"]),
      ...mapState("news", ["notifications", "unreadNotification", "newsTableAdmin"]),
    //   ...mapState("orders", ["adminTableClassHeaders"]),

    },
    data() {
      return {
        countries: [],
        activeItem: {},
        activeSite:{},
        openSetups: false,
        isEditMode: false,
        routeName:'',
        setupsMenu: setupsMenu,
  
        is_admin_system: null
      };
    },
  
    methods: {
      ...mapActions("sites", ["getSites", "getGetAdminSystems"]),
      ...mapActions("clients", ["getClients"]),
      ...mapActions("news", ["_getAllNot", "_getUnreadCount"]),

      
    },
    async mounted() {
    //   await this.getSites();
    //   await this.getClients();
    //   await this.getGetAdminSystems();
    this.loading = true;
     await this._getUnreadCount();
     await this._getAllNot();
    this.loading = false
    },
  };
  </script>